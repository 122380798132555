const state = {
  currentWork: null,
};

const mutations = {
  updateWork(nextState, work){
    nextState.currentWork = work;
  },
};

const actions =  {
  setCurrentWork: (context, work) => context.commit('updateWork', work),
  removeCurrentWork: (context) => context.commit('updateWork', null),
};

export default {
  state,
  actions,
  mutations,
};
