const state = {
  currentAdditionPayroll: null,
  currentExpense: null,
  currentPayment: null,
  currentTrade: null,
  currentInOutItem: null,
  currentStatement: null,
};

const mutations = {
  updateCurrentTrade(nextState, trade){
    nextState.currentTrade = trade;
  },
  updateCurrentPayment(nextState, payment){
    nextState.currentPayment = payment;
  },
  updateCurrentExpense(nextState, expense){
    nextState.currentExpense = expense;
  },
  updateAdditionPayroll(nextState, payroll){
    nextState.currentAdditionPayroll = payroll;
  },
  updateCurrentInOutItem(nextState, inOutItem){
    nextState.currentInOutItem = inOutItem;
  },
  updateCurrentStatement(nextState, statement){
    nextState.currentStatement = statement;
  },
};

const actions =  {
  setCurrentPayment: (context, payment) => context.commit('updateCurrentPayment', payment),
  setCurrentExpense: (context, expense) => context.commit('updateCurrentExpense', expense),
  setCurrentAdditionPayroll: (context, payroll) => context.commit('updateAdditionPayroll', payroll),
  setCurrentTrade: (context, trade) => context.commit('updateCurrentTrade', trade),
  setCurrentInOutItem: (context, inOutItem) => context.commit('updateCurrentInOutItem', inOutItem),
  setCurrentStatement: (context, statement) => context.commit('updateCurrentStatement', statement),
};

export default {
  state,
  actions,
  mutations,
};
