const state = {
  currentChartData: null,
};

const mutations = {
  updateChartData(nextState, data){
    nextState.currentChartData = data;
  },
};

const actions =  {
  setCurrentChartData: (context, chartData) => context.commit('updateChartData', chartData),
};

export default {
  state,
  actions,
  mutations,
};
