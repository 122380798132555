<template>
  <div id="app">
    <Sidemenu v-if="isLogin"/>
    <Topbar v-if="isLogin" />
    <router-view/>
  </div>
</template>

<script>
import Topbar from '@/components/Topbar.vue';
import Sidemenu from '@/components/Sidemenu.vue';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'App',
  components: {
    Sidemenu,
    Topbar
  },
  mounted(){
    const loginToken = window.localStorage.getItem('loginToken');
    if(loginToken){
      this.startLogin();
    }else{
      this.$router.push({ name: 'Login'} );
    }
  },
  methods:{
    ...mapActions(['startLogin']),
  },
  computed: mapState({
    isLogin: state => state.isLogin,
  }),
}

</script>

<style lang="scss">
body{
  background-color: #F0F2F5;
}
#app {
  font-family: OpenSans, Microsoft JhengHei,Arial, Helvetica Neue, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
table{
  background-color: white !important;
}
.page-header{
  margin-bottom: 50px;
}

.main-content{
  margin-left: 350px;
  margin-top: 30px;
  padding-right: 50px;
}

.filter{
  margin-top: 30px;
  .fa-filter{
    margin-right: 10px;
  }
}

.view-toolbars{
  display: flex;
  position: relative;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}

</style>
