const state = {
  currentCustomer: null,
  currentOrder: null,
};

const mutations = {
  updateCustomer(nextState, customer){
    nextState.currentCustomer = customer;
  },
  setCurrentOrder(nextState, order){
    nextState.currentOrder = order;
  },
};

const actions =  {
  setCurrentCustomer: (context, customer) => context.commit('updateCustomer', customer),
  removeCurrentCustomer: (context) => context.commit('updateCustomer', null),

  setCurrentOrder: (context, order) => context.commit('setCurrentOrder', order),
  removeCurrentOrder: (context) => context.commit('setCurrentOrder', null),
};

export default {
  state,
  actions,
  mutations,
};
