const state = {
  currentEmployee: null,
  currentEmployeeGroup: null,
  currentApplication: null,
  currentWorkshift: null,
  currentWorkshifts: [],
  showScheduleForm: false,
};

const mutations = {
  updateEmployeeGroup(nextState, employeeGroup){
    nextState.currentEmployeeGroup = employeeGroup;
  },
  updateEmployee(nextState, employee){
    nextState.currentEmployee = employee;
  },
  updateApplication(nextState, application){
    nextState.currentApplication = application;
  },
  updateWorkshift(nextState, workshift){
    nextState.currentWorkshift = workshift;
  },
  updateWorkshifts(nextState, workshifts){
    nextState.currentWorkshifts = workshifts;
  },
  controlScheduleForm(nextState, status){
    nextState.showScheduleForm = status;
  },
};

const actions =  {
  setCurrentEmployee: (context, employee) => context.commit('updateEmployee', employee),
  removeCurrentEmployee: (context) => context.commit('updateEmployee', null),

  setCurrentEmployeeGroup: (context, employeeGroup) => context.commit('updateEmployeeGroup', employeeGroup),
  removeCurrentEmployeeGroup: (context) => context.commit('updateEmployeeGroup', null),

  setCurrentApplication: (context, application) => context.commit('updateApplication', application),
  removeCurrentApplication: (context) => context.commit('updateApplication', null),

  setCurrentWorkshift: (context, workshift) => context.commit('updateWorkshift', workshift),
  removeCurrentWorkshift : (context, workshift) => context.commit('updateWorkshift', null),

  setCurrentWorkshifts: (context, workshifts) => context.commit('updateWorkshifts', workshifts),
  removeCurrentWorkshifts : (context, workshifts) => context.commit('updateWorkshifts', null),

  displayScheduleForm: (context) => context.commit('controlScheduleForm', true),
  hiddenScheduleForm: (context) => context.commit('controlScheduleForm', false),
};

export default {
  state,
  actions,
  mutations,
};
