import Vue from 'vue';
import Vuex from 'vuex';
import hr from '@/store/hr';
import sales from '@/store/sales';
import work from '@/store/work';
import chart from '@/store/chart';
import financial from '@/store/financial';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: false,
    currentUser: null,
    backendUrl: process.env.VUE_APP_BACKEND_URL,
    apiUrl: process.env.VUE_APP_API_URL,
  },
  mutations: {
    setLoginStatus(nextState, loginStatus){
      nextState.isLogin = loginStatus;
    },
    updateUser(nextState, user){
      nextState.currentUser = user;
    }
  },
  actions: {
    async setUser(context, user){
      context.commit('updateUser', user);
    },
    async removeUser(context){
      context.commit('updateUser', null);
    },
    async startLogin(context){
      context.commit('setLoginStatus', true);
    },
    async startLogout(context){
      context.commit('setLoginStatus', false);
    },
  },
  modules: {
    hr,
    sales,
    work,
    chart,
    financial,
  },
})
