import dayjs from 'dayjs';
import queryString from 'query-string';
require('dayjs/locale/zh-hk');

const Common = {
  checkIsAdmin(user){
    const employeeGroups = user['employee_groups'];
    for(let i = 0; i < employeeGroups.length; i++){
      const employeeGroup = employeeGroups[i];
      if(employeeGroup.type === 'Manager'){
        return true;
      }
    }
  },
  async getUserInfo(baseApiUrl, userId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      });
      const apiUrl = `${baseApiUrl}/employee/${userId}?${query}`;

      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200){
        const err = 'Cannot loading user information.';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 403){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async loadConfig(baseApiUrl, configName, loginInfo){
    return new Promise(async(resolve, reject) => {
      const queryList = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      };

      const query = queryString.stringify(queryList);

      const apiUrl = `${baseApiUrl}/config/${configName}?${query}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'GET',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot load system config. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async updateConfig(baseApiUrl, configName, configValue, loginInfo){
    return new Promise(async(resolve, reject) => {
      const putValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        value: configValue,
      };

      const apiUrl = `${baseApiUrl}/config/${configName}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(putValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot update system config. Maybe there are some fields or network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  filterItems(filter, items){
    const newItems = items.filter(function(item){
      for(let i = 0; i < Object.keys(filter).length; i++){
        const searchKey = Object.keys(filter)[i];
        const searchValue = filter[searchKey];
        if(searchValue.length !== 0 && isNaN(searchValue)){
          if(item[searchKey].toLowerCase().indexOf(searchValue.toLowerCase()) !== 0){
            return false;
          }
        }
        if(searchValue.length !== 0 && !isNaN(searchValue)){
          if(item[searchKey] != searchValue){
            return false;
          }
        }
      }
      return true;
    });
    return newItems;
  },
  getLoginInfo(){
    const currentUserId = window.localStorage.getItem('userId');
    const token = window.localStorage.getItem('loginToken');
    const loginInfo = {
      userId: currentUserId,
      token,
    };
    return loginInfo;
  },
  formatDateTime(timestamp, dateFormat, langcode){
    let date = dayjs.unix(timestamp);
    if(langcode){
      date = dayjs.unix(timestamp).locale(langcode);
    }
    date = date.format(dateFormat);
    return date;
  },
  //The function which will could accept the date format.
  getCurrentDate(dateFormat, langcode){
    let now = dayjs();
    if(langcode){
      now = dayjs().locale(langcode);
    }
    const currentDate = now.format(dateFormat);
    return currentDate;
  },
  getCurrentTimestamp(){
    let now = dayjs();
    return now.unix();
  },
}

export default Common;
